/**
 * @param {*} value
 * @param {object} mapper
 * @returns {string}
 */
export const mapperFmt = function (value, mapper) {
  return mapper?.[value] ?? '⏤'
}
/**
 * 1是0否
 * @param {*} value
 * @returns {('是'|'否')}
 */
export const ynFmt = function (value) {
  return mapperFmt(value, { 1: '是', 0: '否' })
}
/**
 * 1详情图视频 0主图视频
 * @param {*} value
 * @returns {('详情图视频'|'主图视频')}
 * @description video type
 */
export const vtFmt = function (value) {
  return mapperFmt(value, { 1: '详情图视频', 0: '主图视频' })
}
/**
 * 1否0是
 * @param {*} value
 * @returns {('是'|'否')}
 */
export const nyFmt = function (value) {
  return mapperFmt(value, { 1: '否', 0: '是' })
}

/**
 * 1贸易0生产
 * @param {*} value
 * @returns {('生产'|'贸易')}
 */
export const psFmt = function (value) {
  return mapperFmt(value, { 1: '贸易', 0: '生产' })
}

/**
 * 1有0无
 * @param {*} value
 * @returns {('有'|'无')}
 * @description hn, have or not 有或者无
 */
export const hnFmt = function (value) {
  return mapperFmt(value, { 1: '有', 0: '无' })
}

/**
 * @param {string} msg
 * @param {object} options
 * @returns string
 */
export const msgFmt = function (
  msg,
  options = {
    lineBreak: true,
    removeTrailingComma: true,
    removeOldPrefix: false,
    prefix: '',
    suffix: ''
  }
) {
  if (typeof msg !== 'string') return ''

  let copy = msg

  const formatterMapper = {
    lineBreak: () => (copy = copy.replace(/([a-zA-Z])/, '\n$1')),
    removeTrailingComma: () => (copy = copy.replace(/[,，]$/, '')),
    removeOldPrefix: () =>
      (copy = copy.replace(/^[^a-zA-Z]*([a-zA-Z].*)$/, '$1'))
  }

  for (const key of Object.keys(options)) {
    const formatter = formatterMapper[key]
    if (options[key] && formatter) formatter()
  }

  if (options.prefix) {
    copy = options.prefix + copy
  }

  if (options.suffix) {
    copy = copy + options.suffix
  }

  return copy
}

/**
 * @description 转化为中文数字
 * @param {number | string} number
 * @param {'small' | 'big'} type
 * @returns string
 */
export const numberToChinese = function (number, type = 'small') {
  const mapper = {
    0: type === 'small' ? '零' : '零',
    1: type === 'small' ? '一' : '壹',
    2: type === 'small' ? '二' : '',
    3: type === 'small' ? '三' : '',
    4: type === 'small' ? '四' : '',
    5: type === 'small' ? '五' : '',
    6: type === 'small' ? '六' : '',
    7: type === 'small' ? '七' : '',
    8: type === 'small' ? '八' : '',
    9: type === 'small' ? '九' : ''
  }

  return mapper[number]
}

export function optionsFmt(value, options) {
  const matched = options.find((option) => option.value === value)
  if (matched) {
    return matched.label
  } else {
    return '⏤'
  }
}

/**
 * 1贸易0生产 productSchema
 * @param {*} value
 * @returns {('贸易'|'生产')}
 */
export const pSFmt = function (value) {
  return mapperFmt(value, { 1: '贸易', 0: '生产' })
}
export default {
  mapping: mapperFmt,
  yn: ynFmt,
  ny: nyFmt,
  hnFmt,
  msgFmt,
  numberToChinese,
  optionsFmt,
  vtFmt,
  pSFmt
}
