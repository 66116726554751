import service from '@/service'

/**
 * @description 物流对接列表查询
 */
export function getList(data) {
  return service['api-web']({
    url: '/tm/logisticsDockList/tm/getList',
    method: 'post',
    data
  })
}

/**
 * @description 再次对接
 */
export function connectAgain(data) {
  return service['api-web']({
    url: '/tm/logisticsDockList/tm/manualDock',
    method: 'post',
    data
  })
}

/**
 * @description 标记完成
 */
export function markCompleted(data) {
  return service['api-web']({
    url: '/tm/logisticsDockList/tm/markCompleted',
    method: 'post',
    data
  })
}
