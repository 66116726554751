/**
 * @module api-web:cggzt
 * @description
 */
import service from '@/service'

// 列表查询接口
export function getList(data) {
  return service['api-scm']({
    url: '/scm/workspace/purchase/getList',
    method: 'post',
    data
  })
}

// 转采购
export function goPurchase(data) {
  return service['api-scm']({
    url: '/scm/workspace/purchase/transfer',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 供应商
 * @param {{supplierName:string}} params
 */
export function getSupplierInformationPullList(params) {
  return service['api-web']({
    url: '/public/data/getSupplierInformationPullList',
    method: 'get',
    params
  })
}

// 提交
export function goSubmit(data) {
  return service['api-scm']({
    url: '/scm/workspace/purchase/submit',
    method: 'post',
    data
  })
}
