<template>
  <div class="table-selector">
    <vxe-pulldown
      ref="vxe-pulldown"
      class="table-selector-vxe-pulldown"
      transfer
    >
      <template #default>
        <vxe-input
          v-model.trim="row[column.property]"
          :suffix-icon="triggerType === 'focus' ? 'vxe-icon--caret-bottom' : ''"
          class="table-selector-input"
          v-bind="bindInputAttrs"
          @focus="onFocus"
          @blur="onBlur"
          @click="onClick"
          @keyup="onKeyup"
          @change="onChange"
          @suffix-click="onSuffixClick"
        />
      </template>
      <template #dropdown>
        <div class="table-selector-dropdown">
          <vxe-grid
            v-if="hasPagination"
            ref="grid"
            style="z-index: 99 !important"
            size="mini"
            auto-resize
            show-overflow
            show-header-overflow
            v-bind="vxeGridProps"
            @cell-click="onSelect"
            @page-change="onGridPageChange"
            @resizable-change="onHeaderDragend"
          />
          <vxe-table
            v-else
            ref="table"
            resizable
            size="mini"
            auto-resize
            v-bind="vxeTableProps"
            @cell-click="onSelect"
            @resizable-change="onHeaderDragend"
          >
            <vxe-column
              v-for="(item, idx) in vxeTableHeader"
              :key="item?.id || idx"
              :field="item.prop"
              :title="item.label"
              :type="item.type"
              :formatter="item?.formatter"
              :width="
                item.type
                  ? item.width
                    ? item.width
                    : '60'
                  : item.width || '100'
              "
              resizable
              :min-width="45"
              v-bind="item"
            />
          </vxe-table>
        </div>
      </template>
    </vxe-pulldown>
  </div>
</template>

<script>
import { isArray, isNumeric, isPlainObject, isString } from '@/utils/validate'
import { debounce, debounceNew } from '@/utils'
import { extractSubsetByKeys } from '@/utils/object'
import listHeaderCache from '@/storage/local/listHeader'
/**
 * @typedef {Object} Query
 * @property {string} keywords
 * @property {number=} page
 * @property {number=} limit
 * @property {string=} sort
 */
export default {
  name: 'VxeTableSelect',
  props: {
    renderOpts: {
      type: Object,
      default: () => ({
        events: {
          /**
           * @type {(Function|Null)}
           * @description 激活
           */
          onFocus: null,
          /**
           * @type {(Function|Null)}
           * @description 选中
           */
          onSelect: null,
          /**
           * @type {(Function|Null)}
           * @description 还原
           */
          onRevert: null,
          /**
           * @type {(Function|Null)}
           * @description 更新
           */
          onUpdate: null,
          /**
           * @type {(Function|Null)}
           * @description 失焦
           */
          onBlur: null
        },
        attrs: {
          input: {},
          /**
           * @type {(Boolean|Null)}
           * @description 下拉表格是否分页
           */
          pagination: null,
          /**
           * @type {(Function|Array|Null)}
           * @description 远程获取表格行方法 | 行数组
           */
          row: [],
          columns: [
            // { type: 'xxx', width: 'xxxx' },
            // { prop: 'xxx', label: 'xxxx' },
            // { prop: 'xxx', label: 'xxxx' }
            // ...
          ],
          columnConfig: {
            resizable: true,
            resizableCacheKeySalt: ''
          }
        },
        /**
         * @type {('row'|'prop')}
         * @description prop：按字段赋值 row: 全量合并选中行的字段到当前表格行行
         */
        selectType: 'prop',
        selectProp: '',
        triggerType: 'focus'
      })
    },
    scope: {
      type: Object,
      default: () => ({
        row: {}, // 宿主表格行
        column: {} // 宿主表格列
      })
    }
  },
  data() {
    return {
      dropdownShow: false,
      row: {}, // 宿主表格行
      column: {}, // 宿主表格列
      $pulldown: null, // 下拉 $ref
      /**
       * 下拉分页表 props
       * @see vxe-grid  https://xuliangzhan_admin.gitee.io/vxe-table/#/grid/api
       */
      vxeGridProps: {
        /**
         * @type {('default'|'full'|'outer'|'inne'|'none')}
         * @description default（默认） full（完整边框） outer（外边框） inner（内边框） none（无边框）
         */
        border: 'default',
        size: 'mini',
        highlightHoverRow: true,
        autoResize: true,
        height: 'auto',
        loading: false,
        columns: [],
        // data: [],
        rowConfig: {
          isCurrent: true
        },
        columnConfig: {
          resizable: true
        },
        pagerConfig: {
          total: 0,
          currentPage: 1,
          pageSize: 10
        }
      },
      /**
       * 下拉 vxe 表格 props
       * @see vxe-table  https://xuliangzhan_admin.gitee.io/vxe-table/#/grid/api
       */
      vxeTableProps: {
        /**
         * @type {('default'|'full'|'outer'|'inne'|'none')}
         * @description default（默认） full（完整边框） outer（外边框） inner（内边框） none（无边框）
         */
        border: 'default',
        size: 'mini',
        showOverflow: true,
        showHeaderOverflow: true,
        showFooterOverflow: true,
        highlightHoverRow: true,
        autoResize: true,
        height: 'auto',
        loading: false
        // data: []
      },
      /**
       * 下拉 vxe 表格 header(columns)
       */
      vxeTableHeader: []
    }
  },
  computed: {
    /**
     * renderOpts.events
     */
    bindEvents() {
      try {
        const events = this.renderOpts?.events || {}
        return events
      } catch (error) {
        console.error(error)
      }
      return {}
    },
    /**
     * renderOpts.attrs
     */
    renderAttrs() {
      try {
        return this.renderOpts?.attrs || {}
      } catch (error) {
        console.error(error)
      }
      return {}
    },

    bindInputAttrs() {
      const base = {
        placeholder: '请输入',
        size: 'mini'
      }
      const { input = {} } = this.renderAttrs

      if (!isPlainObject(input)) {
        console.error('"input" must be object that for <vxe-input> bind')
        return base
      }

      if (input.disabled && typeof input.disabled === 'function') {
        input.disabled = input.disabled(this.row)
      }
      return { ...base, ...input }
    },

    /**
     * selectProp
     * @description 选中表格下拉项匹配到输入框的属性名
     */
    selectProp() {
      try {
        const selectProp = this.renderAttrs.selectProp
        return selectProp || ''
      } catch (error) {
        console.error(error)
      }
      return ''
    },
    /**
     * selectType
     * @description 选中类型：选中当前值 or 选中当前行
     * @returns {('prop'|'row')}
     */
    selectType() {
      const TYPE = ['prop', 'row']
      try {
        const selectType = this.renderAttrs.selectType
        return TYPE.includes(selectType) ? selectType : TYPE[0]
      } catch (error) {
        console.error(error)
      }
      return TYPE[0]
    },
    /**
     * triggerType
     * @description 下拉触发类型：聚焦 or  输入
     * @returns {('input'|'focus')}
     */
    triggerType() {
      const TYPE = ['input', 'focus']
      try {
        const triggerType = this.renderAttrs.triggerType
        return TYPE.includes(triggerType) ? triggerType : TYPE[0]
      } catch (error) {
        console.error(error)
      }
      return TYPE[0]
    },
    /**
     * 下拉表格是否分页
     */
    hasPagination() {
      try {
        const hasPagination = this.renderAttrs.pagination
        return !!hasPagination
      } catch (error) {
        console.error(error)
      }
      return false
    },
    /**
     * 表头更新
     */
    changeRender() {
      try {
        const columns = isArray(this.renderAttrs?.columns)
          ? this.renderAttrs.columns
          : []
        return `${this.dropdownShow}${this.hasPagination}${JSON.stringify(
          columns
        )}`
      } catch (error) {
        console.error(error)
      }
      return ''
    },
    /**
     * @return {Query} 表格查询参数
     */
    query() {
      try {
        const base = { keywords: this.row[this.column.property] || '' }
        if (this.hasPagination) {
          const { currentPage: page, pageSize: limit } =
            this.vxeGridProps.pagerConfig
          return {
            sort: '',
            page,
            limit,
            ...base
          }
        } else {
          return base
        }
      } catch (error) {
        console.error(error)
      }

      return { keywords: '' }
    },

    resizableCacheKey() {
      const resizableCacheKeySalt =
        this.renderOpts?.attrs?.columnConfig?.resizableCacheKeySalt || ''

      return (
        'vxe-table-selector' +
        (resizableCacheKeySalt ? '-' + resizableCacheKeySalt : '')
      )
    }
  },
  watch: {
    changeRender: {
      immediate: true,
      handler() {
        if (this.resizableCacheKey) {
          const { key, setup } = listHeaderCache

          this.setTableHeader(
            setup(
              key.call(this, this.resizableCacheKey),
              this.renderAttrs.columns
            )
          )
        } else this.setTableHeader(this.renderAttrs.columns)
      }
    },
    'renderOpts.attrs.columnConfig': {
      immediate: true,
      handler(columnConfig) {
        if (isPlainObject(columnConfig)) {
          // eslint-disable-next-line no-unused-vars
          const { resizableCacheKeySalt, ...rest } = columnConfig
          this.vxeGridProps.columnConfig = rest
        }
      }
    }
  },
  created() {
    Object.assign(this, this.scope)
  },
  mounted() {
    this.$pulldown = this.$refs['vxe-pulldown']
  },
  methods: {
    /**
     * 设置表头
     * @param {Boolean} hasPagination - 是否分页
     * @param {Array} columns - 表格列（表头）
     */
    setTableHeader(columns) {
      if (isArray(columns)) {
        if (this.hasPagination) {
          this.vxeGridProps.columns = columns
            .map((item) => ({
              ...item,
              field: item.prop,
              title: item.label,
              formatter: item?.formatter
            }))
            .filter((item) => item.type !== 'none')
        } else {
          this.vxeTableHeader = columns.filter((item) => item.type !== 'none')
          console.log(
            '🚀 ~ file: VxeTableSelect.vue:405 ~ setTableHeader ~  this.vxeTableHeader :',
            this.vxeTableHeader
          )
        }
      } else {
        console.error('Losed "columns" (for table header)')
      }
    },
    /**
     * dropdown table loading
     */
    setTableLoading(loading) {
      this.$set(
        this[this.hasPagination ? 'vxeGridProps' : 'vxeTableProps'],
        'loading',
        !!loading
      )
    },
    /**
     * dropdown table body
     */
    async setTableData(data) {
      if (isArray(data)) {
        if (this.hasPagination) {
          if (this.$refs.grid) {
            this.$refs.grid.reloadData(data)
          }
        } else {
          if (this.$refs.table) {
            this.$refs.table.reloadData(data)
          }
        }
      }
    },
    /**
     * get table data
     * @param {Query} query
     */
    async getData(query) {
      try {
        await this.$halt(0)
        const row = this.renderAttrs.row
        if (typeof row === 'function') {
          // 远程查询
          this.setTableLoading(true)

          this.setTableData([])
          const data = await row(query)
          await this.$halt(16)

          let arr = []
          let total = 0

          if (isPlainObject(data)) {
            if (isArray(data?.list)) arr = data.list
            if (isNumeric(data?.total)) total = data.total * 1
          } else if (isArray(data)) {
            arr = data
          } else console.error('Get illegal data from response', data)
          this.setTableData(arr)
          if (!data?.total) {
            total = data?.length
          }
          this.vxeGridProps.pagerConfig.total = total
          // 设置首行为高亮行
          await this.$refs.grid.setCurrentRow(data[0])
        } else if (isArray(row)) {
          // 过滤
          this.setTableData(
            query.keywords
              ? row.filter(
                  ({ _XID, _X_ID, ...rest }) =>
                    Object.values(rest)
                      .join('')
                      .toLowerCase()
                      .indexOf(query.keywords.toLowerCase()) > -1
                )
              : row
          )
        } else {
          console.error(
            '"row" must be 「"function" that retrun array」 or just 「"array"」'
          )
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.setTableLoading(false)
      }
    },
    /**
     * keyup event on input
     */
    onKeyup: debounceNew(function (e) {
      if (this.bindInputAttrs?.readonly === true) return
      if (e && e?.$event.code === 'Enter') {
        const curRow = this.$refs.grid.getCurrentRecord()
        Object.entries(
          extractSubsetByKeys(
            curRow,
            this.renderAttrs.columns.map((v) => v.prop)
          )
        ).forEach(([k, v]) => (this.row[k] = v))
        // 关闭弹窗
        this.hideDropdown()
        this.cleared = false
        // 解决 bug/8093 选中后暂不移开鼠标，商品气泡停留在选择时位置，不会消失
        const el = document.querySelector(
          '.vxe-table--tooltip-wrapper.is--visible'
        )
        el?.classList.remove('is--visible')
        if (typeof this.bindEvents.onBlur === 'function') {
          this.bindEvents.onBlur(this.row, this.column)
        }
        return
      }
      if (isArray(this.renderAttrs.row)) {
        if (!this.dropdownShow) this.showDropdown()
        this.getData(this.query)
      } else {
        if (this.query.keywords) {
          if (!this.dropdownShow) this.showDropdown()
          this.getData(this.query)
        } else {
          if (this.dropdownShow) this.hideDropdown()
        }
      }
    }, 500),
    /**
     * show
     */
    async showDropdown() {
      await this.$pulldown.showPanel()
      this.dropdownShow = true
    },
    /**
     * hide
     */
    async hideDropdown() {
      await this.$pulldown.hidePanel()
      this.dropdownShow = false
      this.setTableData([])
      this.setTableLoading(false)
    },
    /**
     * toggle
     */
    async toggleDropdown() {
      await this[this.dropdownShow ? 'hideDropdown' : 'showDropdown']()
    },

    async onFocus({ value, $event }) {
      if (this.bindInputAttrs?.readonly === true) return

      if (!value || (isString(value) && !value.trim())) {
        if (typeof this.bindEvents.onFocus === 'function') {
          this.bindEvents.onFocus()
        }
      }
    },

    async onBlur({ value, $event }) {
      if (this.bindInputAttrs?.readonly === true) return
      if (!value || (isString(value) && !value.trim())) {
        // await this.bindEvents.onRevert(this.row, {
        //   source: 'table-select-blur'
        // })
        return
      }

      let currentDropdownList = []
      if (this.hasPagination) {
        if (this.$refs?.grid) {
          currentDropdownList = this.$refs.grid
            .getTableData()
            .tableData.map(({ _XID, _X_ID, ...rest }) =>
              Object.values(rest).join('').toLowerCase()
            )
        } else console.error('losed refs:gird', this.$refs)
      } else {
        if (this.selectProp) {
          if (this.$refs?.table) {
            currentDropdownList = this.$refs.table
              .getTableData()
              .tableData.map((v) => v[this.selectProp].toLowerCase())
          } else console.error('losed refs:table', this.$refs)
        }
      }

      if (
        !currentDropdownList.filter(
          (item) => item.indexOf(value.toLowerCase()) > -1
        ).length
      ) {
        // await this.bindEvents.onRevert(this.row, {
        //   source: 'table-select-blur'
        // })
      }
    },

    async onClick() {
      if (this.bindInputAttrs?.readonly === true) return

      switch (this.triggerType) {
        case 'input': {
          if (this.query.keywords) {
            if (this.dropdownShow) {
              this.hideDropdown()
            } else {
              this.getData(this.query)
              this.showDropdown()
            }
          } else {
            if (this.dropdownShow) {
              this.hideDropdown()
            }
          }
          break
        }
        default: {
          if (this.dropdownShow) {
            this.hideDropdown()
          } else {
            this.getData(this.query)
            this.showDropdown()
          }
          break
        }
      }
    },
    async onSuffixClick() {
      if (this.bindInputAttrs?.readonly === true) return
      // await this.$pulldown.togglePanel()
      await this.toggleDropdown()
      this.dropdownShow && this.getData(this.query)
    },
    /**
     * 下拉表格翻页
     */
    onGridPageChange({ currentPage, pageSize }) {
      if (this.vxeGridProps.pagerConfig) {
        this.vxeGridProps.pagerConfig.currentPage = currentPage
        this.vxeGridProps.pagerConfig.pageSize = pageSize
      }
      this.getData(this.query)
    },
    onHeaderDragend({ $rowIndex, column, columnIndex, $columnIndex, $event }) {
      debounce(
        function () {
          const { key, updateColumnWidth } = listHeaderCache
          updateColumnWidth(
            key.call(this, this.resizableCacheKey),
            column,
            column?.resizeWidth || column?.width || 'auto'
          )
        }.bind(this),
        400
      )()
    },

    /**
     * input change
     */
    onChange({ value, $event }) {
      console.log('on input')
      if (this.cleared === true) return

      switch (this.selectType) {
        case 'prop': {
          this.row[this.column.property] = null
          break
        }
        case 'row': {
          Object.entries(
            extractSubsetByKeys(
              this.row,
              this.renderAttrs.columns
                .map((v) => v.prop)
                .filter((prop) => prop !== this.column.property)
            )
          ).forEach(([k, v]) => {
            if (this.row[k]) this.row[k] = null
          })
          break
        }
        default:
          console.error('Unknown "selectType"')
          break
      }

      if (typeof this.bindEvents.onChange === 'function') {
        this.bindEvents.onChange(value, $event)
      }

      this.cleared = true
      // this.$table.updateStatus({ row: this.row, column: this.column })
    },
    /**
     * row pick
     */
    async onSelect(evt) {
      console.log('on select')
      if (!evt) return void console.error('onSelect')
      const changed = evt.row.value !== this.row[this.column.property]
      try {
        switch (this.selectType) {
          case 'prop': {
            if (isString(this.selectProp) && this.selectProp) {
              this.row[this.column.property] = evt.row[this.selectProp]
            }
            break
          }
          case 'row': {
            Object.entries(
              extractSubsetByKeys(
                evt.row,
                this.renderAttrs.columns.map((v) => v.prop)
              )
            ).forEach(([k, v]) => (this.row[k] = v))
            break
          }
          default:
            console.error('Unknown "selectType"')
            break
        }

        await this.bindEvents.onUpdate(this.row, this.column)

        if (typeof this.bindEvents.onSelect === 'function') {
          this.bindEvents.onSelect(
            {
              changed,
              $table: this.scope.$table,
              row: this.row,
              column: this.column
            },
            evt.row
          )
        }
      } catch (error) {
        console.error(error)
      }

      this.cleared = false
      // this.$table.updateStatus({ row: this.row, column: this.column })
      this.hideDropdown()

      // 解决 bug/8093 选中后暂不移开鼠标，商品气泡停留在选择时位置，不会消失
      const el = document.querySelector(
        '.vxe-table--tooltip-wrapper.is--visible'
      )
      el?.classList.remove('is--visible')
    }
  }
}
</script>

<style lang="scss" scoped>
// 使用vxe-table默认的z-index
// .table-selector-vxe-pulldown {
//   z-index: 99 !important;
// }
:deep(.vxe-table--ignore-clear) {
  z-index: 99 !important;
}
:deep(.vxe-table--tooltip-wrapper) {
  z-index: 9999 !important;
}
:deep(.vxe-body--column) {
  z-index: 9999 !important;
  .vxe-cell--label {
    z-index: 9999 !important;
  }
}
.table-selector {
  &-vxe-pulldown {
    width: 100%;
  }

  &-dropdown {
    width: 400px;
    height: 290px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
  }

  .vxe-input--suffix {
    cursor: pointer;
  }
}
</style>
