/**
 * @module api-activiti:instance
 */
import service from '@/service'

/**
 * @param {{keywords:string,limit:number,page:number,sort:string}} params
 * @description  根据流程实例名称查询流程实例列表
 */
export function getList(params) {
  return service['api-activiti']({
    url: '/sys/process/instance/getList',
    method: 'get',
    params
  })
}
/**
 * @param {{instanceId:string}} params
 * @description 删除流程实例
 */
export function remove(params) {
  return service['api-activiti']({
    url: '/sys/process/instance/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{instanceName:string,processDefinitionKey:string}} data
 * @description 启动流程实例
 */
export function start(data) {
  return service['api-activiti']({
    url: '/sys/process/instance/start',
    method: 'put',
    data
  })
}
/**
 * @param {string} instanceId
 * @description 挂起流程实例
 */
export function suspend(instanceId) {
  return service['api-activiti']({
    url: '/sys/process/instance/suspend',
    method: 'put',
    data: { instanceId }
  })
}
/**
 * @param {string} instanceId
 * @description 恢复流程实例
 */
export function resume(instanceId) {
  return service['api-activiti']({
    url: '/sys/process/instance/resume',
    method: 'put',
    data: { instanceId }
  })
}
/**
 * @param {string} instanceId
 * @description 获取流程实例参数
 */
export function getInstanceParams(instanceId) {
  return service['api-activiti']({
    url: '/sys/process/instance/variables',
    method: 'get',
    params: { instanceId }
  })
}
/**
 * @param {string} processInstanceId
 * @description 获取流程实例参数
 */
export function getBusinessKey(processInstanceId) {
  return service['api-activiti']({
    url: '/sys/process/instance/getBusinessKey',
    method: 'get',
    params: { processInstanceId }
  })
}
/**
 * @param {string} processInstanceId
 * @description  根据流程实例id获取 流程图 xml string
 */
export function getXML(processInstanceId) {
  return service['api-activiti']({
    url: '/sys/process/instance/exportXmlByInstId',
    method: 'get',
    params: { processInstanceId },
    headers: { 'Content-Type': 'application/xml' }
  })
}

/**
 * @param {('node'|'line')} type 流程流转历史记录类型：环节(节点) 或者 流(线条)
 * @param {string} processInstanceId 流程实例 ID
 * @description  根据流程实例id获取 流程实例流转历史
 */
export function getHistory(processInstanceId, documentCode, type = 'node') {
  let api = ''
  let params = {}
  if (type === 'node') {
    api = 'getInstanceHis'
    params = { processInstanceId }
  } else if (type === 'line') {
    api = 'getHighLightedFlows'
    params = { processInstanceId }
  } else {
    return Promise.resolve({
      code: -1,
      msg: `Illeagl "type":${type}, only support "node" or "line"`,
      data: null
    })
  }

  return service['api-activiti']({
    url: `/sys/process/instance/${api}`,
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

// 采购订单 销售订单代办审核 加锁
export function lockOrder(data) {
  return service['api-activiti']({
    url: '/public/lock',
    method: 'post',
    data
  })
}
// 采购订单 销售订单代办审核 解锁
export function unlockOrder(data) {
  return service['api-activiti']({
    url: '/public/unlock',
    method: 'post',
    data
  })
}
