/**
 * @module api-scm:cc
 * @description
 */
import service from '@/service'

// 列表查询接口
export function getList(params) {
  return service['api-scm']({
    url: '/cc/shippingRule/get/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

// 删除接口
export function deleteData(ids) {
  return service['api-scm']({
    url: '/cc/shippingRule/delete',
    method: 'delete',
    params: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

// 获取详情接口
export function getInfo(id) {
  return service['api-scm']({
    url: '/cc/shippingRule/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

// 新增加接口
export function save(data) {
  return service['api-scm']({
    url: '/cc/shippingRule/save',
    method: 'post',
    data
  })
}

// 发货规则配置表修改接口
export function update(data) {
  return service['api-scm']({
    url: '/cc/shippingRule/update',
    method: 'put',
    data
  })
}

// 启用禁用
export function updateDisableStatus(data) {
  return service['api-scm']({
    url: '/cc/shippingRule/updateDisableStatus',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
