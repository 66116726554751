/**
 * @module api-web:financialAffairs/accountPayable/estimationPayable
 * 暂估应付单
 */
import service from '@/service'
/**
 * 查询
 * @param {{limit:number,page:number,sort:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/ap/finEstimatePurchase/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *   树形表格下拉
 * @param {code} code
 */
export function getListDown(id) {
  return service['api-web']({
    url: '/fin/ap/finEstimatePurchase/public/getPullList',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 根据 ID 查询财务收款单详情
 * @param {number} id
 */
export function info(id) {
  return service['api-web']({
    url: '/fin/ap/finEstimatePurchase/info',
    method: 'get',
    params: { id }
  })
}
/**
 * 待办修改
 * @param {Department} data
 */
export function todoUpdate(data) {
  return service['api-web']({
    url: '/public/activiti/finEstimatePurchase/update',
    method: 'put',
    data
  })
}
/**
 * 提交审核
 * @param {Array<number|string >} ids
 */
export function submit(ids) {
  return service['api-web']({
    url: '/fin/ar/finEstimatePurchase/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {object}
 * @returns 审核驳回
 */
// export function reject(data) {
//   return service['api-web']({
//     url: '',
//     method: 'post',
//     data
//   })
// }

/**
 * @param {array} data
 * @description 反核销
 */
export function reverseWriteOff(data) {
  return service['api-web']({
    url: '/fin/ap/finEstimatePurchase/public/reverseWriteOff',
    method: 'post',
    data
    // Content-Type 是json
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {string} id
 * @description 反审核
 */
export function rollback(id) {
  return service['api-web']({
    url: '/fin/ap/finEstimatePurchase/public/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {number} id
 * @description 作废
 */
export function invalid(id) {
  return service['api-web']({
    url: '/fin/ap/finEstimatePurchase/public/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:Array<number>}} params - id
 * @description 删除
 */
// export function remove(params) {
//   return service['api-web']({
//     url: '/fin/ar/finEstimatePurchase/delete',
//     method: 'delete',
//     params,
//     headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
//   })
// }

/**
 * @param {object} data -id
 * @description 下推
 */
export function pushDown(data) {
  return service['api-web']({
    url: '/fin/ap/finEstimatePurchase/public/pushDown',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:string}}
 * @
 */
export function withdraw(ids) {
  return service['api-web']({
    url: '/fin/ar/finEstimatePurchase/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 创建 新增
 * @param {object} data
 * @returns {object}
 */
export function create(data) {
  return service['api-web']({
    url: '/fin/ap/finEstimatePurchase/save',
    method: 'post',
    data
  })
}

/**
 * @description 获取成本信息
 */
export function getCostInformation(ids) {
  return service['api-web']({
    url: '/fin/ap/finEstimatePurchase/getCostInformation',
    method: 'get',
    params: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @description 导出数据
 */
export function exportData(data) {
  return service['api-web']({
    url: '/fin/ap/finEstimatePurchase/exportEstimatePurchaseData',
    method: 'post',
    data,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: true, // 文件流是否通过浏览器下载
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 暂估应付单导入（手动导入时使用）
 */
export function importEstimatePurchaseData(data) {
  return service['api-web']({
    url: '/fin/ap/finEstimatePurchase/importEstimatePurchaseData',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}
/**
 *  下载模板
 */
export function downloadExcel() {
  return service['api-web']({
    url: '/fin/ap/finEstimatePurchase/public/downloadExcel',
    method: 'post',
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: false, // 文件流是否通过浏览器下载， 下载的模板文件名需要有 buttonGroup 配置，所有不通过 axios 下载
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 *  批量作废
 * @param {object} data
 */

export function batchCancellation(data) {
  return service['api-web']({
    url: '/fin/ap/finEstimatePurchase/public/batchCancellation',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
