import { getInfo, getPermissions } from '@/service/auth'
import { get, set, remove } from '@/storage'

const USER_INFO_KEY = 'user-info'
const ROLE_MENU_KEY = 'role-menu'

export async function getUserInfo() {
  const cache = get('session', USER_INFO_KEY)

  const isUsingCache = getWhetherUsingCacheOrNot(cache)

  if (isUsingCache) {
    return cache
  } else {
    const remoteData = await getInfo()

    return remoteData?.data || null
  }
}

export function setUserInfo(info) {
  set('session', USER_INFO_KEY, info)
}
export function removeUserInfo() {
  remove('session', USER_INFO_KEY)
}

export async function getRoleMenu() {
  const cache = get('session', ROLE_MENU_KEY)

  const isUsingCache = getWhetherUsingCacheOrNot(cache)

  if (isUsingCache) {
    return cache
  } else {
    const remoteData = await getPermissions()
    console.log('🚗', remoteData)

    return remoteData?.data || []
  }
}

export function setRoleMenu(menu) {
  set('session', ROLE_MENU_KEY, menu)
}
export function removeRoleMenu() {
  remove('session', ROLE_MENU_KEY)
}

function getWhetherUsingCacheOrNot(cache) {
  const isJumpingFromOtherPages =
    performance.getEntriesByType('navigation')?.[0]?.type === 'navigate'

  return cache && isJumpingFromOtherPages
}
