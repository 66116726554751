/**
 * @module api-scm:warehouse/warehouse
 */
import service from '@/service'

/**
 * 列表查询
 * @param {{
 * keywords:string,
 * limit:number,
 * page:number,
 * sort:string,
 * productCode:string,
 * productName:string,
 * date:Array<string>
 * }} params
 */
export function getList(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseMaterialPickUp/get/getList',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @param {(string|number)} code
 * @description 树形表格下拉
 */
export function getListDown(code) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseMaterialPickUp/get/getListDown',
    method: 'get',
    params: { code },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *新增
 * @param {object} data
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseMaterialPickUp/save',
    method: 'post',
    data
  })
}

/**
 * 详情
 * @param {number} id
 * @returns {any}
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseMaterialPickUp/info',
    method: 'get',
    params: { id }
  })
}

/**
 * //
 * @param {{string}} id
 * @returns
 * @description  待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-scm']({
    url: '/public/activiti/warehouseMaterialPickUp/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *修改
 * @param {object} data
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseMaterialPickUp/update',
    method: 'post',
    data
  })
}
/**
 * @param {object} data
 */
export function submit(ids) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseMaterialPickUp/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 *  流程待办：驳回
 * @param {object} data
 */
export function reject(data) {
  return service['api-scm']({
    url: '/public/activiti/warehouseMaterialPickUp/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {string} ids
 * @description 作废
 */
export function invalid(ids) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseMaterialPickUp/cancellation',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseMaterialPickUp/withdraw',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {{ids:string}} params
 * @description 删除  params
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseMaterialPickUp/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 导出原料辅料申请
 */
export function exportData(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseMaterialPickUp/dataExport',
    method: 'post',
    data,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: true // 文件流是否通过浏览器下载
  })
}

export function pushDown(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseMaterialPickUp/pushDown',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{keywords:string}} params
 */
export function getUserlists(params) {
  return service['api-web']({
    url: '/public/getUserlists',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 批量粘贴商品编码
 */
export function blockPasteSearchProductInfoWithInventory(data) {
  return service['api-web']({
    url: '/public/product/blockPasteSearchProductInfoWithInventory',
    method: 'post',
    data
  })
}
