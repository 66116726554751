/**
 * @module api-web:demandLabel
 */
import service from '@/service'

/**
 * 查询需求标签列表
 */
export function getList(data) {
  return service['api-web']({
    url: '/data/demandLabel/get/getList',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 需求标签修改
 */
export function update(data) {
  return service['api-web']({
    url: '/data/demandLabel/update',
    method: 'post',
    data
  })
}

/**
 * 获取日志列表
 */
export function getLogList(params) {
  return service['api-web']({
    url: '/data/demandLabel/get/getLogList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
