/**
 * @module api-scm:sellInOutWarehouse/sellOutWarehouse
 */
import service from '@/service'

/**
 * 售后申请   列表查询
 * @param {{
 * keywords:string,
 * limit:number,
 * page:number,
 * sort:string,
 * outWarehouseCode:string,
 * productCode:number,
 * productName:string,
 * purchaseApplyReturnCode:string,
 * supplierCode:string,
 * date:Array<string>
 * }} params
 */
export function getList(data) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderOut/get/getList',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 *   树形表格下拉
 * @param {string} code
 */
export function getListDown(code) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderOut/get/getListDown',
    method: 'get',
    params: { orderOutCode: code },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

// /**
//  * 创建
//  * @param {object} data
//  * @returns {object}
//  */
// export function create(data) {
//   return service['api-scm']({
//     url: '/scm/purchaseWarehouse/warehousePurchaseApplyReturn/saveAll',
//     method: 'post',
//     data
//   })
// }

/**
 * 更新
 * @param {object} data
 * @returns {object}
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderOut/update',
    method: 'post',
    data
  })
}
/**
 * @param {Array<number|string>} ids
 * @description 提交
 */
export function submit(ids) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderOut/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 审核驳回
 * @param {object} data
 */

export function reject(data) {
  return service['api-scm']({
    url: '/public/activiti/warehouseOrderOut/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 详情
 * @param {number} id
 * @returns {any}
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderOut/info',
    method: 'get',
    params: { id }
  })
}

/**
 *
 * @param {{string}} id
 * @returns
 * @description  待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-scm']({
    url: '/public/activiti/warehouseOrderOut/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {number} id
 * @description 作废
 */
export function invalid(id) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderOut/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {number} id
 * @description 反审核
 */
export function rollback(id) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderOut/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderOut/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {{ids:string}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderOut/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 导出数据
 */
export function exportData(data) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderOut/warehouseOrderOut/export',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * @description 导出销售出库单
 */
export function exportSummary(data) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderOut/exportSummary',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * @description 手动对接
 */
export function manualDocking(data) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderOut/manualDock',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 确认拆单提醒
 */
export function getRemainRules(params) {
  return service['api-web']({
    url: '/cc/orderRemainCenter/get/getRemainRules',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 销售出库单详情页面明细行调整库存
 */
export function adjustInventory(data) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderOut/get/adjustInventory',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 根据组织、仓库、库位查询sku的所有批次
 */
export function queryAllSkuBatchCode(data) {
  return service['api-scm']({
    url: '/scm/warehouse/inventoryDetail/queryAllSkuBatchCode',
    method: 'post',
    data
  })
}
