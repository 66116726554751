import service from '@/service'
/**
 * @description 单据列表查询
 */
export function orderList(params) {
  return service['api-web']({
    url: '/dc/kingdee/getDockOrderList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 对接处理结果查询
 */
export function getList(params) {
  return service['api-web']({
    url: '/dc/kingdee/getDockResultList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 查看结果
 */
export function viewResult(params) {
  return service['api-web']({
    url: '/dc/kingdee/getResult',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 手动对接
 */
export function manualDock(data) {
  return service['api-web']({
    url: '/dc/kingdee/manualDock',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * @description 结果导出
 */
export function resultExport(params) {
  return service['api-web']({
    url: '/dc/kingdee/resultExport',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: true // 文件流是否通过浏览器下载
  })
}

/**
 * @description 单据列表导出
 */
export function exportDockOrderList(data) {
  return service['api-web']({
    url: '/dc/kingdee/exportDockOrderList',
    method: 'post',
    data,
    responseType: 'blob' // 文件流接收类型声明转成 blob
  })
}

/**
 * @description 修改日期
 */
export function updateBusinessDate(data) {
  return service['api-web']({
    url: '/dc/kingdee/updateBusinessDate',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}
