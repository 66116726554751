/**
 * @module api-scm:procurementOrder.jc
 */

import service from '@/service'

/**
 * @param {{
 * productCode:string,
 * supplier:string}} params
 * @description  查询列表
 */
export function getList(data) {
  return service['api-scm']({
    url: '/scm/purchase/application/getList',
    method: 'post',
    data
  })
}

/**
 * 创建
 * @param {object} data
 * @returns {object}
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/purchase/application/save',
    method: 'post',
    data,
    tipTypes: 'none'
  })
}

/**
 * 更新
 * @param {object} data
 * @returns {object}
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/purchase/application/update',
    method: 'post',
    data,
    tipTypes: 'none'
  })
}
/**
 *  提交
 * @param {Array<string|number>} ids
 */

export function submit(ids) {
  return service['api-scm']({
    url: '/scm/purchase/application/submit',
    method: 'get',
    params: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  下推
 * @param {object} data
 */

export function pushDown(data) {
  return service['api-scm']({
    url: '/scm/purchase/application/pushDown',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 详情
 * @param {number} id
 * @returns {any}
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/purchase/application/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:string}} params
 * @description 删除
 */
export function remove(data) {
  return service['api-scm']({
    url: '/scm/purchase/application/delete',
    method: 'POST',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 审核驳回
 * @param {object} ids
 */

export function reject(data) {
  return service['api-scm']({
    url: '/public/activiti/purchaseApplication/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 待办详情 (无需权限)
 * @param {{string}} id
 * @returns
 */
export function todoInfo(id) {
  return service['api-scm']({
    url: '/public/activiti/application/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {number} id
 * @description 作废
 */
export function invalid(ids) {
  return service['api-scm']({
    url: '/scm/purchase/application/cancelPAS',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @description 下载
 */
export function downloadPATemplate(data) {
  return service['api-scm']({
    url: '/scm/purchase/application/downloadPATemplate',
    method: 'post',
    data,
    responseType: 'blob',
    saveAs: false, // 文件流是否通过浏览器下载
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {{id:number | string,path:string}} data
 * @description 导入
 */
export function importPurchaseApplication(data) {
  return service['api-scm']({
    url: '/scm/purchase/application/import',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}
/**
 *
 * @description 导出
 */
export function exportContract(params) {
  return service['api-scm']({
    url: '/scm/purchase/application/downloadPdf1',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: true // 文件流是否通过浏览器下载
  })
}
