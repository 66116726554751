<template>
  <div class="batchVague">
    <el-select
      v-model="computedValue"
      placeholder="请选择"
      size="mini"
      @change="selectChange"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'BatchVagueSelect',
  props: {
    value: {
      type: [Number, String],
      default: 0
    }
  },

  data() {
    return {
      options: [
        {
          label: '模糊',
          value: 0
        },
        {
          label: '批量',
          value: 1
        }
      ]
    }
  },

  computed: {
    computedValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  },
  methods: {
    selectChange(val) {
      this.$emit('selectChange')
    }
  }
}
</script>

<style lang="scss" scoped>
.batchVague {
  width: 100px;
}
</style>
