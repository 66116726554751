/**
 * @module custom-renderers:VxeToolTipInput
 * @path @/src/plugins/vxe-table/
 */
import VXETable from 'vxe-table'
import VxeToolTipInput from './VxeToolTipInput'
import VxeToolTipSpan from './VxeToolTipSpan'

VXETable.renderer.add('VxeToolTipInput', {
  renderEdit: VxeToolTipInputFunc,
  renderCell: VxeToolTipSpanFunc
})

function VxeToolTipInputFunc(h, renderOpts, params = {}) {
  // console.log('params', params)
  try {
    const { events = {}, props = {}, autoselect, enterSave } = renderOpts
    const { content, fields, showTip, ...rest } = props
    return [
      <VxeToolTipInput
        content={`${content}`}
        fields={`${fields}`}
        scope={params}
        otherEvent={events}
        autoselect={autoselect}
        enterSave={enterSave}
        vxe-input-props={rest}
        showTip={showTip}
      ></VxeToolTipInput>
    ]
  } catch (error) {
    console.error(error)
  }
  return [<VxeToolTipInput />]
}

function VxeToolTipSpanFunc(h, renderOpts, params = {}) {
  try {
    const { props = {} } = renderOpts
    const { content, fields, type, digits = 0 } = props
    return [
      <VxeToolTipSpan
        content={`${content}`}
        fields={`${fields}`}
        value-type={type}
        value-digits={digits}
        scope={params}
      ></VxeToolTipSpan>
    ]
  } catch (error) {
    console.error(error)
  }
  return [<VxeToolTipSpan />]
}
