import { consoleTheme } from '@/settings'
import { getDocStatus, getDisabledStatus } from './uni-fields'

const FILTER = {
  1: '过滤出「无需审核」或「已审核」且「启用」状态的选项',
  2: '过滤出「无需审核」或「已审核」且「启用/禁用」状态的选项',
  3: '过滤出「启用」且「无需审核」或「创建/审核中/已审核/重新审核」状态的选项',
  4: '取消过滤'
}

const DISABLER = {
  1: '禁用所有 非「已审核」且未「启用」的选项',
  2: '禁用所有 非「已审核」状态的选项',
  3: '只禁用 未「启用」状态的选项 ',
  4: '取消过滤'
}

/**
 * @param {Array} options
 * @param {(1|2|3)} [filterType=1] 过滤类型 1:根据单据状态和启用禁用状态同时过滤 2:只根据单据状态过滤 3:只根据启用禁用状态过滤4:不做过滤直接展示
 * @returns {Array}
 */
export function optionsCommonFilter(options, filterType = 1) {
  console.groupCollapsed('OPTIONS GLOBAL FILTER')

  console.group('原始选项')
  console.log([...options])
  console.groupEnd()

  console.group('过滤规则')
  console.log(FILTER[filterType])
  console.groupEnd()
  let _options = [...options]
  console.group('选项明细')
  switch (filterType) {
    // filter options which non-approvaled or disabled out
    case 1:
      _options = options.filter((v) => !unApprovalOrDisabled(v))
      break
    // filter options which non-approvaled out
    case 2:
      _options = options.filter((v) => !unApproval(v))
      break
    // filter options which disabled out
    case 3:
      _options = options.filter((v) => !isDisabled(v))
      break
    // no filter options
    case 4:
      _options = options
      break
  }
  console.groupEnd()
  console.groupEnd()
  return _options
}

/**
 * @param {Array} options
 * @param {(1|2|3)} [disableType=1] 类型 1:根据单据审核状态和单据启用禁用状态同时禁用 2:禁用 非【已审核】单据 3:禁用【禁用】状态的单据
 * @returns {Array}
 */
export function optionsCommonDisabler(options, disableType = 1) {
  console.groupCollapsed('OPTIONS GLOBAL DISABLER')
  console.group('原始选项')
  console.log([...options])
  console.groupEnd()

  console.group('禁用规则')
  console.log(DISABLER[disableType])
  console.groupEnd()
  let _options = [...options]
  console.group('选项明细')
  switch (disableType) {
    // disable options which non-approvaled or disabled
    case 1:
      _options = options
        .map((v) => ({
          ...v,
          disabled: unApprovalOrDisabled(v)
        }))
        .sort((a, b) => a.disabled * 1 - b.disabled * 1)
      break
    // disable options which non-approvaled
    case 2:
      _options = options
        .map((v) => ({
          ...v,
          disabled: unApproval(v)
        }))
        .sort((a, b) => a.disabled * 1 - b.disabled * 1)
      break
    // disable options which disabled
    case 3:
      _options = options
        .map((v) => ({
          ...v,
          disabled: isDisabled(v)
        }))
        .sort((a, b) => a.disabled * 1 - b.disabled * 1)
      break
  }
  console.groupEnd()
  console.groupEnd()
  return _options
}
/**
 *
 * @param {*} item
 * @returns {boolean}
 */
export function unApprovalOrDisabled(item) {
  const documentStatus = getDocStatus(item)
  const disableStatus = getDisabledStatus(item)

  let result = false
  switch (true) {
    // item 对象有单据状态值和启用禁用的flag
    case typeof disableStatus === 'number' &&
      typeof documentStatus === 'number':
      result = !(disableStatus === 0 && documentStatus === 3)
      break
    // item 对象只有启用禁用的flag
    case typeof disableStatus === 'number' &&
      typeof documentStatus !== 'number':
      result = isDisabled(disableStatus)
      break
    // item 对象只有单据状态值
    case typeof disableStatus !== 'number' &&
      typeof documentStatus === 'number':
      result = unApproval(documentStatus)
      break
    default:
      console.warn('non-status', documentStatus, disableStatus)
      break
  }
  console.groupCollapsed(
    `%c ${item?.label ?? 'Unkown label'}  ${documentStatus} | ${disableStatus}`,
    `background: ${consoleTheme.bgColor.transparent}; color: ${
      consoleTheme.txtColor[result === true ? 'red' : 'green']
    }; `
  )
  console.table({
    label: item?.label ?? 'Unkown label',
    value: item?.value,
    documentStatus,
    disableStatus
  })
  console.groupEnd()

  return result
}

/**
 * @return {boolean} true:非审核通过状态 false:已审核状态（3）
 */
export function unApproval(documentStatus, item) {
  const _documentStatus =
    documentStatus ?? (item ? getDocStatus(item) : undefined)

  if (typeof _documentStatus === 'undefined') {
    console.warn('【unApproval】missing document status', documentStatus, item)
  } else if (![1, 2, 3, 4].includes(_documentStatus)) {
    console.warn('【unApproval】illeagl document status', documentStatus, item)
  }

  return _documentStatus !== 3
}

/**
 * @return {boolean}  true:禁用 false:启用（1）
 */
export function isDisabled(disabledStatus, item) {
  const _disableStatus =
    disabledStatus ?? (item ? getDisabledStatus(item) : undefined)

  if (typeof _disableStatus === 'undefined') {
    console.warn('【isDisabled】missing disabled status', disabledStatus, item)
  } else if (![0, 1].includes(_disableStatus)) {
    console.warn('【isDisabled】illegal disabled status', disabledStatus, item)
  }

  return _disableStatus === 1
}
