/**
 * @module api-web:cc
 * @description
 */
import service from '@/service'

// 列表查询接口
export function getList(params) {
  return service['api-web']({
    url: '/eco/jd/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  下载模板
 */
export function downloadExcel() {
  return service['api-web']({
    url: '/eco/jd/downloadExcel',
    method: 'post',
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: true, // 文件流是否通过浏览器下载， 下载的模板文件名需要有 buttonGroup 配置，所有不通过 axios 下载
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 安赛瑞导入
 */
export function importASR(data) {
  return service['api-web']({
    url: '/eco/jd/importASR',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}
/**
 * @description 本九导入
 */
export function importBJ(data) {
  return service['api-web']({
    url: '/eco/jd/importBJ',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * @description 英思坦导入
 */
export function importYST(data) {
  return service['api-web']({
    url: '/eco/jd/importYST',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * @description 获取任务详情列表
 */
export function getTaskInfoList(params) {
  return service['api-web']({
    url: '/eco/jd/getTaskInfoList',
    method: 'get',
    params
  })
}

/**
 * @description 导入任务详情中失败的数据
 */
export function exportFailTaskInfo(params) {
  return service['api-web']({
    url: '/eco/jd/exportFailTaskInfo',
    method: 'get',
    params,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: true // 文件流是否通过浏览器下载
  })
}

/**
 * @description 单个同步
 */
export function singleSync(data) {
  return service['api-web']({
    url: '/eco/jd/singleSync',
    method: 'post',
    data
  })
}
